import { useWallet } from '@solana/wallet-adapter-react';
import { useState, useEffect } from 'react';
import { HeatMapOutlined, HeartOutlined, CloudServerOutlined } from '@ant-design/icons';

import { Link } from 'umi';
import './index.css';

export default function Tools() {
    const { publicKey } = useWallet();
    document.title = 'Tools for Solana / Solist.App';
    return (
        <div>
            <h1>Tools</h1>
            <p>Some helping tools.</p>
            <div className="main flex-wrap">
                <Link to="/tools/nftmint">
                    <HeatMapOutlined />
                    <div className="more">
                        <h2>NFT Mint</h2>
                        <p>Mint Metaplex NFT by contract</p>
                    </div>
                </Link>                
            </div>
        </div>
    );
}
