import { Menu } from 'antd';
import { Link } from 'umi';


export default function ValidatorsMenu(props) {

    return (
        <Menu            
            mode="horizontal"     
            defaultSelectedKeys={props.selected}
            items={[
                {
                    label: <Link to="/validators">All nodes</Link>,
                    key: 'all',            
                },    
                {
                    label: <Link to="/validators/my">My Favourite</Link>,
                    key: 'my',                
                },    
                {
                    label: <Link to="/validators/rpc">RPC</Link>,
                    key: 'rpc',                
                }, 
                {
                    label: <Link to="/validators/geo">Geo</Link>,
                    key: 'geo',                
                },
            ]}
        />
    );
}
