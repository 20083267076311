import { useWallet } from '@solana/wallet-adapter-react';
import { useState, useEffect } from 'react';
import { Button, Form, Input, Space } from 'antd';
import { networks, networkData } from '../utils/config';
import { Link } from 'umi';
import { useModel } from 'umi';
import './index.css';

export default function Tools() {
    const { publicKey } = useWallet();
    const wallet = useWallet();
    const { address, network, nfts, nftsLoading, getNfts, mintLoading, mintNFT, candyLoading, getCandyMachine, candy } = useModel('networkModel');
    document.title = 'Tools for Solana / Solist.App';
    return (
        <div className="miniWidth">
            <h1>Mint NFT by contract</h1>
            <p>Candy machine testing.</p>
            <div className="basic">                 
                <Form
                    layout="vertical"
                    onFinish={(values) => {
                        mintNFT(values?.contract, wallet);
                    }}
                >
                    <Form.Item
                        name="contract"
                        label="Mint contract"
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>  
                    <Form.Item>                                
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            block
                            loading={mintLoading}
                        >
                            Mint
                        </Button> 
                    </Form.Item>  
                </Form>             
                          
            </div>
        </div>
    );
}
