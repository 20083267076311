import { PlusCircleOutlined } from '@ant-design/icons';
import { basicAPY } from '../utils/config';


export default function APY(props) {   
    let apy = (basicAPY * ((100 - props.children)/100)).toFixed(1);
    return (
     <span
        className="APY"      
        title="APY" 
        style={{
            color: '#ffd591',
            marginLeft: '8px'
        }}
     >
        <PlusCircleOutlined /> ~{apy}% APY
     </span>
    );
}
