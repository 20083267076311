// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/var/www/crm/solist/src/models/balance';
import model_2 from '/var/www/crm/solist/src/models/networkModel';

export const models = {
model_1: { namespace: 'balance', model: model_1 },
model_2: { namespace: 'networkModel', model: model_2 },
} as const
