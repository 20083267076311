import { useState, useEffect, useCallback } from 'react';
import { Button, Drawer } from 'antd';
import { Outlet } from 'umi';

export default function DrawerButton(data)
{
    const [visible, setVisible] = useState(false);

    const {
        children,
        link,
        component,
        props,
        drawer = {},
        ...buttonProps
    } = data;

    const Component = component;

    const onOpen = () => {
        if (props?.beforeOpen !== undefined)
                props?.beforeOpen();
        setVisible(true)
    };

    return (<>
        {link 
            ? <a
					{...buttonProps}
					onClick={() => onOpen()}
				>
					{children}
				</a>
			:
				<Button
					{...buttonProps}
					onClick={() => onOpen()}
				>
					{children}
				</Button>
        }
        <Drawer
            destroyOnClose
            width={600}
            onClose={() => setVisible(false)}
            open={visible}
            {...drawer}
        >
            <Component
				{...props}
				onClose={() => {
                    if (props?.onSave !== undefined)
                        props?.onSave();
                    setVisible(false);
                }}
			/> 
        </Drawer>
        
    </>);
}