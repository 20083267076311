
import { Popover, Table, TableProps, Button  } from 'antd';
import { LoadingOutlined, MenuOutlined } from '@ant-design/icons';
import { useModel, useNavigate  } from 'umi';
import { useState, useEffect, useCallback } from 'react';
import { Link, Outlet } from 'umi';
import DrawerButton from '../../../components/DrawerButton';
import Valist from '../../../components/Valist';
import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup } from "react-simple-maps";
import ValidatorsMenu from '../__menu';

import './index.css';

const geoUrl = "https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json";
type TableRowSelection<T extends object = object> = TableProps<T>['rowSelection'];

const rowSelection: TableRowSelection<DataType> = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
    },
  };
  

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const GeoNodesPage = () => {
    document.title = 'Solana Nodes by geographical location';
    const { 
        address,    
        network, 

        getFavValidators,
        favValidatorsLoading,
        favValidators,

        geoLoading,
        geo,
        getGeo,

        getGeoNodes,
        geoNodesLoading,
        geoNodes,

    } = useModel('networkModel');

    const [width, setWidth]   = useState(window.innerWidth);

    useEffect(() => {      
        getGeo(); 
        if (address && !favValidators)
        getFavValidators();       
    }, [network]);

    const updateDimensions = () => {
        setWidth(window.innerWidth);       
    }

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    let navigate = useNavigate();

    let geos = [];
    if (geo.length > 0)
    {
        geo.map(a => {            
            a.cities?.map(b => {
                geos.push({
                    geo: [b.lng, b.lat],
                    name: b.name,
                    country: a.name,
                    active: b.active,
                    delinquent: b.delinquent,
                    activeStakePct: b.activeStakePct,
                });
            });
        });
    }

    console.log('geos', geos.length);

    return (
        <div className="validators-geo">         
            <h1>Solana Nodes by geographical location</h1>    
            <ValidatorsMenu selected="geo" />            
            <div className="map">
                <ComposableMap>
                    <ZoomableGroup center={[0, 0]} zoom={1}>
                    <Geographies geography={geoUrl}>
                    {({ geographies }) =>
                        geographies.map((geo) => (
                            <Geography key={geo.rsmKey} geography={geo} />
                        ))
                    }
                    </Geographies>
                    {(geo.length > 0) && geos.map(a =>   <Marker coordinates={a.geo} fill="#777">
                        <Popover title={a.name + ', ' + a.country} content={<><div>{a.active.count} nodes, {a.active.activeStake} $SOL, {a.activeStakePct}% stake</div><div>{a.delinquent.count} delinquent nodes</div></>}><circle className="mapCircle" fill="#F53" stroke="#000" r={2} /></Popover>
                    </Marker>)}
                    </ZoomableGroup>
                </ComposableMap>
            </div>

            <Table                
                dataSource={geo}
                loading={geoLoading ? {indicator: antIcon} : false}
                childrenColumnName={'cities'}
                pagination={false}
                owSelection={{ ...rowSelection }}
                columns={[
                    {
                        id: 'country',
                        key: 'name',
                        title: 'Country',
                        width: '200px',
                        render: (value, record, index) => {
                            if (record.code === undefined)
                                    return <></>;
                            return <><img className="flag" src={"https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.6.6/flags/4x3/" + record.code?.toLowerCase() + '.svg'} /> {record.name}</>;
                        }
                    },
                    {
                        id: 'cities',
                        key: 'cities',
                        title: 'Cities',
                        render: (text, row, index) => {
                            if (row.cities !== undefined)
                            {
                                if (row.cities.length > 3)
                                    return <>{row.cities.length} cities</>;
                                else
                                    return row.cities.map(i => i.name).join(', ');
                            } else  
                                return <>{row.name}</>;
                                
                        }
                    },
                    {
                        id: 'stake',
                        key: 'stake',
                        title: 'Stake',
                        render: (text, row, index) => {
                            return <>
                                <div className="activeNodes">{row.active.count} active nodes</div>
                                <div className="subActiveNodes">{row.active.activeStake} $SOL, {row.activeStakePct}% of staked</div>
                                <div className="delinquentNodes">{row.delinquent.count} nodes delinquent with {row.delinquent.activeStake} $SOL staked</div>
                            </>;
                        }
                    },
                    {
                        id: 'stake',
                        key: 'stake',
                        title: ' ',  
                        width:'150px',
                        render: (value, row, index) => {
                            let filter = {
                                chain: network,
                                country: row.countryCode || row.code,
                                city: row.cities === undefined ? row.name : undefined
                            };
                            return <DrawerButton                              
                                component={Valist}
                                icon={<MenuOutlined />}
                                props={{
                                    beforeOpen: () => getGeoNodes(filter),
                                    loading: geoLoading,
                                    list: geoNodes || [],
                                }}
                                drawer={{
                                    title: 'Nodes from ' + row.name + ' (' + (row.countryCode || row.code) + ')',
                                    width: width > 1400 ? '1400px' : '100%'
                                }}
                            >Nodes</DrawerButton>;
                        }                     
                    },
                    
                ]}
            />
           
           
        </div>
    );
};

export default GeoNodesPage;
