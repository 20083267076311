
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { AccountInfo, LAMPORTS_PER_SOL, ParsedAccountData, PublicKey, Connection, Transaction, StakeProgram, sendAndConfirmTransaction, Keypair, Lockup, Authorized  } from '@solana/web3.js';

import { useState, useEffect, useCallback } from 'react';
import { Button, Select, Form, Alert, InputNumber, notification } from 'antd';
import { networks, networkData } from '../utils/config';
import { useModel } from 'umi';
import { LoadingOutlined, PlusOutlined, HeartFilled } from '@ant-design/icons';

import APY from './APY';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default function StakeForm(props)
{
    const [visible, setVisible] = useState(false);
    const { publicKey } = useWallet();
    const wallet = useWallet();
    const { 
        address,
        network,
        balance,
        getStakes,
        stakes,
        stakesLoading,
        getValidators,
        validators,
        validatorsLoading,
        epoch,
        getEpochInfo,
        epochLoading,
        getBalance,
        favValidators,
        favValidatorsLoading,
        getFavValidators
    
    } = useModel('networkModel');

    const [ selectedValidator, setSelectedValidator ] = useState([]);
    const [ newLamports, setNewLamports ] = useState(2);

    useEffect(() => {
        if (!address)
            return console.log('New stake: no wallet');
        getStakes(address);
        if (!validators?.length)
            getValidators();             
        getFavValidators();
        if (!epoch)
            getEpochInfo();
    }, [address, network]);

    useEffect(() => {
        if (props.selected !== undefined)
        {
            setSelectedValidator(props.selected);
            setNewLamports(2);
        }
    }, []);

    let fav = favValidators.map(i => i.pubkey);
    let sortedValidators = validators;
    sortedValidators.sort(function(a, b) 
    {        
       let aFav = fav.includes(a.pubkey);
       let bFav = fav.includes(b.pubkey);        
       if (aFav == bFav) 
           return 0;
       if (aFav && !bFav)
           return -1;
       else
           return 1;
    });

    return (<Form
        layout="vertical"
    >

        <Form.Item
            label="Select validator"
            extra="For more APY, we recommend stake to validators with 10% or less commission"
        >
            <Select                
                style={{width:'100%'}}
                placeholder="Search by name, pubkey, vote..."
                showSearch
                value={selectedValidator.vote}
                optionFilterProp="children"
                onChange={(e) => {                               
                    let val = validators.filter(i => i.vote == e)[0];                          
                    setSelectedValidator(val);
                    setNewLamports(2);
                }}
                filterOption={(input, option) =>
                    (option?.pubkey ?? '').toLowerCase().includes(input.toLowerCase())
                        || (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
                        || (option?.data?.name ?? '').toLowerCase().includes(input.toLowerCase())
                        || (option?.data?.twitter ?? '').toLowerCase().includes(input.toLowerCase())
                        || (option?.data?.keybaseUsername ?? '').toLowerCase().includes(input.toLowerCase())
                        || (option?.data?.description ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={sortedValidators.map((item) => ({
                    label: <div className="valo">
                        <div className="name">{(fav.includes(item.pubkey)) && (<HeartFilled />)} {item.data?.name || item.vote}</div>
                        <div className="extra flex">
                            <div className="comm">{item.commission || 0}%</div>
                            <APY>{item.commission || 0}</APY>
                            <div className="staked">{(item.activestake / LAMPORTS_PER_SOL).toLocaleString('ru-RU', {style: 'currency', currency: 'SOL', maximumFractionDigits: 0})}</div>
                            {(item.country !== '') && (<div className="location">
                                    <img src={"https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.6.6/flags/4x3/" + item.country.toLowerCase() + '.svg'} />
                                    {item.country}, {item.city}
                            </div>)}

                        </div>
                    </div>,
                            value: item.vote,
                            data: item.data,
                            pubkey: item.pubkey,
                    }))}
            >
            </Select>
            {(networkData[network].ourVote) && ( <Alert message={<div>We recommend our validator <Button type="link" size="small" onClick={() => { setSelectedValidator(validators.filter(i => i.vote == networkData[network].ourVote)[0]); }}>Synergy</Button></div>} type="info" showIcon />)}

        </Form.Item>

        {(selectedValidator.vote && balance > 2) && (
            <>
                <Form.Item label="How many SOL?" extra={'min 2 SOL, max ' + (balance - 0.1) + ' SOL'}>
                    <InputNumber
                        value={newLamports || 2}
                        precision={2}
                        min={2}
                        max={balance - 0.1}
                        style={{width:'150px'}}
                        onChange={(e) => {
                            setNewLamports(e);
                        }}
                    />
                </Form.Item>
                <Form.Item extra="Stake will be activated in next epoch.">
                    <Button
                            type="primary"
                            block
                            onClick={() => {
                                const stakePubkey = Keypair.generate();
                                notification.open({
                                    placement: 'bottomRight',
                                    key: 'newstake',
                                    icon: antIcon,
                                    message: 'Stake started...',
                                    description: 'created new stake account ' + stakePubkey.publicKey.toString(),
                                    duration: 0,
                                });
                                let connection = new Connection(networkData[network].rpc, "confirmed");
                                connection.getRecentBlockhash('recent')
                                .catch(err => {
                                            return notification.error({
                                                placement: 'bottomRight',
                                                key: 'newstake',
                                                message: 'getLatestBlockhashAndContext error',
                                                description: err.message,
                                                duration: 15,
                                            });
                                        })
                                .then(data => {
                                    if (!data)
                                        return;
                                    const transaction = new Transaction({
                                        blockhash: data.blockhash,
                                        recentBlockhash: data.blockhash,
                                        feePayer: wallet.publicKey,
                                    }).add(
                                        StakeProgram.createAccount({
                                            fromPubkey: wallet.publicKey,
                                            stakePubkey: stakePubkey.publicKey,
                                            authorized: new Authorized(wallet.publicKey, wallet.publicKey),
                                            lockup: new Lockup(0, 0, wallet.publicKey),
                                            lamports: newLamports * LAMPORTS_PER_SOL,
                                        }),
                                        StakeProgram.delegate({
                                            stakePubkey: stakePubkey.publicKey,
                                            authorizedPubkey: wallet.publicKey,
                                            votePubkey: new PublicKey(selectedValidator.vote),
                                         }),
                                    );

                                    wallet.signTransaction(transaction)
                                        .catch(err => {
                                            console.log('SE1', err);
                                            return notification.error({
                                                placement: 'bottomRight',
                                                key: 'newstake',
                                                message: 'Signature error #1',
                                                description: err.message,
                                                duration: 0,
                                            });

                                        })
                                        .then(signed => {
                                            if (!signed)
                                                return;
                                            signed.partialSign(stakePubkey);
                                            notification.open({
                                                placement: 'bottomRight',
                                                key: 'newstake',
                                                icon: antIcon,
                                                message: 'Creating stake account...',
                                                duration: 0,
                                            });

                                            const rawTransaction = signed.serialize({ requireAllSignatures: false });
                                            connection.sendRawTransaction(rawTransaction)
                                            .catch(err => {
                                                console.log('sendRawTransaction', err);
                                                return notification.error({
                                                    placement: 'bottomRight',
                                                    key: 'newstake',
                                                    message: 'Signature error #2',
                                                    description: err.message,
                                                    duration: 0,
                                                });

                                            })
                                            .then(txid => {
                                                if (!txid)
                                                    return;
                                                connection.confirmTransaction(txid, "confirmed")
                                                .catch(err => {
                                                    return notification.error({
                                                        placement: 'bottomRight',
                                                        key: 'newstake',
                                                        message: 'Signature error #3',
                                                        duration: 0,
                                                    });
                                                })
                                                .then(success => {
                                                    if (!success)
                                                        return;
                                                    notification.success({
                                                        placement: 'bottomRight',
                                                        key: 'newstake',
                                                            message: 'Stake  created',
                                                            duration: 15,
                                                        });

                                                        props?.onClose();
                                                       // setNewOpen(false);
                                                       // setTimeout(getStakes(), 3000);
                                                       // setTimeout(getBalance(), 4000);

                                                });
                                            });

                                        });


                                });
                            }}
                        >Stake it!</Button>
                </Form.Item>
            </>
        )}

        {(selectedValidator.vote && balance < 2) && (
            <Form.Item label="How many SOL?">
                <Alert
                    message="Minimum stake (2 SOL) greater than your wallet balance"
                    type="error"
                    showIcon
                />
            </Form.Item>
        )}



    </Form>);
}