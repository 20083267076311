import { AccountInfo, LAMPORTS_PER_SOL, ParsedAccountData, PublicKey } from '@solana/web3.js';
import { LoadingOutlined } from '@ant-design/icons';
import { useModel, useNavigate  } from 'umi';
import { useState, useEffect, useCallback } from 'react';
import { Link, Outlet } from 'umi';
import Valist from '../../components/Valist';
import ValidatorsMenu from './__menu';




import './index.css';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const NodesPage = () => {
    document.title = 'View solana mainnet validators, statistic and more with Solist';
    const { 
        address,

        validators,
        validatorsLoading,
        getValidators,
        
        network,
        setOneValidator,
    
        getFavValidators,
        favValidatorsLoading,
        favValidators,

        getRpcValidators,
        rpcValidatorsLoading,
        rpcValidators,
    } = useModel('networkModel');
    const [ search, setSearch ] = useState('');
    useEffect(() => {
        getValidators();       
        if (address)
            getFavValidators();   
      
    }, [network, address]);

    let navigate = useNavigate();

    return (
        <div className="validators">
            <h1>Validators</h1>

            <ValidatorsMenu selected="all" />

            <Valist
                loading={validatorsLoading}
                list={validators || []}
            />
                 
           
        </div>
    );
};

export default NodesPage;
