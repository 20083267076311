import { AccountInfo, LAMPORTS_PER_SOL, ParsedAccountData, PublicKey } from '@solana/web3.js';
import { Table, Spin, notification, message, Badge, Avatar, Input, Tabs, Menu } from 'antd';
import { LoadingOutlined, MailOutlined } from '@ant-design/icons';
import { useModel, useNavigate  } from 'umi';
import { useState, useEffect, useCallback } from 'react';
import { Link, Outlet } from 'umi';
import Copier from '../../components/Copier';
import Valist from '../../components/Valist';
import ValidatorsMenu from './__menu';



import './index.css';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const NodesPage = () => {
    document.title = 'Solana RPC node validators, statistic and more with Solist';
    const { 
        address,

        validators,
        validatorsLoading,
        getValidators,
        
        network,
        setOneValidator,
    
        getFavValidators,
        favValidatorsLoading,
        favValidators,

        getRpcValidators,
        rpcValidatorsLoading,
        rpcValidators,
    } = useModel('networkModel');
    const [ search, setSearch ] = useState('');
    useEffect(() => {      
        getRpcValidators();   
        if (address)
            getFavValidators();   
    }, [network, address]);

    let navigate = useNavigate();

    return (
        <div className="validators">          
            
            <h1>RPC Nodes</h1>    
            <ValidatorsMenu selected="rpc" />                 

            <Valist
                loading={rpcValidatorsLoading}
                list={rpcValidators || []}
            />
           
        </div>
    );
};

export default NodesPage;
