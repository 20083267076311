// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import routeProps from './routeProps';

if (process.env.NODE_ENV === 'development') {
  Object.entries(routeProps).forEach(([key, value]) => {
    const internalProps = ['path', 'id', 'parentId', 'isLayout', 'isWrapper', 'layout', 'clientLoader'];
    Object.keys(value).forEach((prop) => {
      if (internalProps.includes(prop)) {
        throw new Error(
          `[UmiJS] route '${key}' should not have '${prop}' prop, please remove this property in 'routeProps'.`
        )
      }
    })
  })
}

import React from 'react';

export async function getRoutes() {
  const routes = {"validators/$identity":{"path":"validators/:identity","id":"validators/$identity","parentId":"@@/global-layout"},"validators/geo/index":{"path":"validators/geo","id":"validators/geo/index","parentId":"@@/global-layout"},"validators/__menu":{"path":"validators/__menu","id":"validators/__menu","parentId":"@@/global-layout"},"validators/index":{"path":"validators","id":"validators/index","parentId":"@@/global-layout"},"validators/rpc":{"path":"validators/rpc","id":"validators/rpc","parentId":"@@/global-layout"},"tools/nftmint":{"path":"tools/nftmint","id":"tools/nftmint","parentId":"@@/global-layout"},"validators/my":{"path":"validators/my","id":"validators/my","parentId":"@@/global-layout"},"tools/index":{"path":"tools","id":"tools/index","parentId":"@@/global-layout"},"index":{"path":"/","id":"index","parentId":"@@/global-layout"},"stake":{"path":"stake","id":"stake","parentId":"@@/global-layout"},"swap":{"path":"swap","id":"swap","parentId":"@@/global-layout"},"nft":{"path":"nft","id":"nft","parentId":"@@/global-layout"},"@@/global-layout":{"id":"@@/global-layout","path":"/","isLayout":true}} as const;
  return {
    routes,
    routeComponents: {
'validators/$identity': React.lazy(() => import(/* webpackChunkName: "src__pages__validators__$identity" */'../../../src/pages/validators/$identity.tsx')),
'validators/geo/index': React.lazy(() => import(/* webpackChunkName: "src__pages__validators__geo__index" */'../../../src/pages/validators/geo/index.tsx')),
'validators/__menu': React.lazy(() => import(/* webpackChunkName: "src__pages__validators____menu" */'../../../src/pages/validators/__menu.tsx')),
'validators/index': React.lazy(() => import(/* webpackChunkName: "src__pages__validators__index" */'../../../src/pages/validators/index.tsx')),
'validators/rpc': React.lazy(() => import(/* webpackChunkName: "src__pages__validators__rpc" */'../../../src/pages/validators/rpc.tsx')),
'tools/nftmint': React.lazy(() => import(/* webpackChunkName: "src__pages__tools__nftmint" */'../../../src/pages/tools/nftmint.tsx')),
'validators/my': React.lazy(() => import(/* webpackChunkName: "src__pages__validators__my" */'../../../src/pages/validators/my.tsx')),
'tools/index': React.lazy(() => import(/* webpackChunkName: "src__pages__tools__index" */'../../../src/pages/tools/index.tsx')),
'index': React.lazy(() => import(/* webpackChunkName: "src__pages__index" */'../../../src/pages/index.tsx')),
'stake': React.lazy(() => import(/* webpackChunkName: "src__pages__stake" */'../../../src/pages/stake.tsx')),
'swap': React.lazy(() => import(/* webpackChunkName: "src__pages__swap" */'../../../src/pages/swap.tsx')),
'nft': React.lazy(() => import(/* webpackChunkName: "src__pages__nft" */'../../../src/pages/nft.tsx')),
'@@/global-layout': React.lazy(() => import(/* webpackChunkName: "layouts__index" */'/var/www/crm/solist/src/layouts/index.tsx')),
},
  };
}
